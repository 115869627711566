import { Fragment, useEffect, useMemo, useState } from 'react';
import { compact, keyBy, sortBy } from 'lodash-es';
import { Alert, Autocomplete, Checkbox, SxProps, TextField, Typography } from '@mui/material';
import { CompetitionLeague, League, useCompetitionsQuery, useLeaguesQuery, useMyAccountQuery } from 'src/api';

interface LeaguesSelectProps {
  label?: string;
  disabled?: boolean;
  multiple?: boolean;
  competitionId?: string;
  availableLeagues?: Set<string>;
  activeLeagues?: string[];
  onChange?: (activeLeagues: string[]) => void;
  size?: 'small' | 'medium';
  sx?: SxProps;
}

export function CompetitionLeaguesSelect({
  label = 'Ligen',
  disabled,
  multiple = false,
  competitionId,
  availableLeagues,
  activeLeagues,
  onChange,
  size = 'medium',
  ...sx
}: LeaguesSelectProps) {
  useCompetitionsQuery;
  const myAccount = useMyAccountQuery().data?.myAccount;

  const { data: competitionsData, error: competitionsError } = useCompetitionsQuery();
  const competitions = useMemo(
    () => competitionsData?.competitions.edges?.map((edge) => edge.node),
    [competitionsData],
  );
  const competitionsByKey = keyBy(competitions, 'id');

  const { data: leaguesData, error: leaguesError } = useLeaguesQuery();
  let leagues = useMemo(() => leaguesData?.leagues.edges.map((edge) => edge.node).reverse(), [leaguesData]);

  if (competitionId) {
    leagues = leagues?.filter((league) => league.competitionId === competitionId);
  }

  if (availableLeagues) {
    leagues = leagues?.filter((league) => availableLeagues.has(league.id));
  }

  const leaguesById = keyBy(leagues, 'id');

  const [inputValue, setInputValue] = useState('');

  // `value` needs to be stable to support `inputValue`
  const value = useMemo(
    () => activeLeagues?.map((id) => leaguesById[id]),
    [leaguesData, activeLeagues?.join()], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!leagues?.length || !myAccount?.defaultCompetitionLeagues || activeLeagues?.length) return;

    if (competitionId) {
      const competition = myAccount.defaultCompetitionLeagues.find(
        (dcl) => dcl.competitionId === competitionId && !dcl.hidden,
      ) as CompetitionLeague;
      onChange?.([competition?.leagueId ?? leagues[0].id]);
    } else {
      const dcls = myAccount.defaultCompetitionLeagues
        ?.filter((dcl) => !dcl.hidden)
        .map((dcl) => dcl.leagueId);
      onChange?.(dcls.length ? dcls : [leagues[0].id]);
    }
  }, [leagues?.length, myAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (competitionsError) return <Alert severity="error">Konnte Wettbewerbe nicht laden</Alert>;

  if (leaguesError) return <Alert severity="error">Konnte Ligen nicht laden</Alert>;

  if (!competitions || !leagues) return null;

  return (
    <Autocomplete
      disableCloseOnSelect={multiple}
      disabled={disabled}
      size={size}
      multiple={multiple}
      options={leagues}
      value={value}
      inputValue={inputValue}
      onChange={(_event, entry, reason, details) => {
        let flatLeagues = compact([entry].flat());

        if (reason === 'selectOption' && details?.option.id) {
          flatLeagues = flatLeagues.filter((league) => league.competitionId !== details.option.competitionId);
          flatLeagues.push(details.option);
        }

        onChange?.(sortBy(flatLeagues, 'createdAt').map((league) => league.id));
      }}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (Array.isArray(option) ? option[0]?.name : option?.name) ?? ''}
      groupBy={(league: League) => competitionsByKey[league.competitionId as string]?.name ?? 'Andere'}
      renderGroup={(params) => (
        <Fragment key={params.key}>
          {competitions.length > 1 && (
            <Typography component="div" variant="subtitle1" px={multiple ? 2 : 1} mt={2}>
              {params.group}
            </Typography>
          )}
          {params.children}
        </Fragment>
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {multiple && <Checkbox sx={{ p: 0, pr: 1 }} checked={selected} />}
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} sx={{ minWidth: 220, ...sx }} />}
    />
  );
}
